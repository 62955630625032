import React from "react";

import "./About.css";

const About = () => {
  return (
    <section id="about_container">
      <div className="">
        <div className="heading-container">
          <h2 className="title-text">
            <em>Who We Are</em>
          </h2>
          <h3 className="flex-copy-heading">Our Promise</h3>
          <h4 className="flex-copy-text">
            At Concordville Cleaners, we truly care about what we do and we
            strive to exceed your expectations with every service we provide.
            Our ultimate goal is to build a long-term relationship with our
            customers, and we're committed to providing exceptional service that
            earns your trust and loyalty.
          </h4>
          <br />
          <h3 className="flex-copy-heading">Our Mission</h3>
          <h4 className="flex-copy-text">
            Providing exceptional dry cleaning services with a focus on quality,
            convenience, and affordability is at the heart of what we do. We
            believe in going above and beyond for our customers, ensuring that
            their clothes and household items are always cleaned to perfection.
          </h4>
          <h3 className="flex-copy-heading">Our Goal</h3>
          <h4 className="flex-copy-text">
            Our team of experienced professionals are dedicated to providing the
            best possible service, and we use only the highest quality cleaning
            products and equipment. Whether you need dry cleaning, alterations,
            or household item cleaning, we have the expertise to ensure your
            clothes are always cleaned to perfection.
          </h4>
          <br />

          <h5 className="flex-copy-text">
            Trust us to take care of your cleaning needs, and experience the
            difference that our commitment to quality and customer satisfaction
            can make.
          </h5>
        </div>
      </div>
    </section>
  );
};

export default About;
