import React from "react";
import { AiFillStar } from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";

import "./Card.css";

const Card = ({ review }) => {
  return (
    <div className="card">
      <h5>&ldquo;{review.review}&rdquo;</h5>

      <div className="review-star">
        <AiFillStar role="img" aria-label="Golden Star" />
        <AiFillStar role="img" aria-label="Golden Star" />
        <AiFillStar role="img" aria-label="Golden Star" />
        <AiFillStar role="img" aria-label="Golden Star" />
        <AiFillStar role="img" aria-label="Golden Star" />
      </div>
      <div className="social-container">
        <h4 className="social-heading">{review.name}</h4>
        <span className="social-span">
          <FcGoogle role="img" aria-label="Golden Star" />
        </span>
      </div>
    </div>
  );
};

export default Card;
