import React from "react";
import "./Alterations.css";
import images from "../../constants/images";

const Alterations = () => {
  return (
    <section id="alterations_container">
      <div className="container">
        <div className="copy-container alterations-copy">
          <h2 className="title-text">
            <em>Alterations</em>
          </h2>
          <h3 className="flex-copy-heading">
            Professional Alterations and Repairs
          </h3>
          <h4 className="flex-copy-text">
            Our professional alteration and repair services are perfect for
            those items that need a little extra care. From hemming pants to
            repairing tears, we have the expertise to ensure your clothes look
            and fit perfectly.
          </h4>
        </div>

        <div className="image-container">
          <img
            id="alterations-1"
            src={images.mirrors}
            alt="Mirrors with reflection of clothes inside boutique shop"
          />
        </div>
      </div>
    </section>
  );
};

export default Alterations;
