import "./Hero.css";
import { AiFillStar } from "react-icons/ai";

import images from "../../constants/images";

const Hero = () => {
  return (
    <section id="hero_container">
      <div className="container">
        <div className="flex-40 flex-100 hero-copy">
          <h1 className="hero-heading">Quality Care Exceptional Service</h1>

          <h2 className="hero-subheading">
            Trust us to keep your clothes looking and feeling like new. Our dry
            cleaning services are second to none.
          </h2>
          <div className="hero-stars">
            <AiFillStar role="img" aria-label="Golden Star" />
            <AiFillStar role="img" aria-label="Golden Star" />
            <AiFillStar role="img" aria-label="Golden Star" />
            <AiFillStar role="img" aria-label="Golden Star" />
            <AiFillStar role="img" aria-label="Golden Star" />
          </div>
          <div className="hero-stars-copy">
            <h3>Over 100+ 5 Star Reviews!</h3>
            <h3>Highest Rated Dry Cleaners in the Philadelphia Region!</h3>
          </div>
          <div className="hero-cta">
            <a
              href="https://g.page/Concordville-Cleaners"
              target="_blank"
              rel="noreferrer"
              className="btn-hero"
            >
              Google Reviews
            </a>
            <a
              href="https://www.yelp.com/biz/concordville-cleaners-glen-mills"
              target="_blank"
              rel="noreferrer"
              className="btn-hero"
            >
              Yelp Reviews
            </a>
          </div>
        </div>
        <div className="hero-image" id="hero-jacket">
          <img src={images.heroImg1} alt="Jacket hanging in front of a door" />
        </div>
      </div>
    </section>
  );
};

export default Hero;
