import React from "react";
import "./Delivery.css";
import images from "../../constants/images";

const Delivery = () => {
  return (
    <section id="delivery_container">
      <div className="container">
        <div className="image-container delivery-order-img">
          <img
            id="door"
            src={images.door2}
            alt="door opening to courtyard of house"
          />
        </div>
        <div className="copy-container delivery-copy delivery-order-copy">
          <h2 className="title-text">
            <em>Delivery</em>
          </h2>
          <h3 className="flex-copy-heading">
            Convenient Pickup and Delivery Options
          </h3>
          <h4 className="flex-copy-text">
            We understand that you have a busy schedule, which is why we offer
            convenient pickup and delivery options for your dry cleaning needs.
            Our reliable and efficient service ensures that your clothes are
            picked up and delivered on time, every time. Our delivery service is
            easy to sign up for and completely automated.
          </h4>
        </div>
      </div>
    </section>
  );
};

export default Delivery;
