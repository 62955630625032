import { useState } from "react";
import Card from "../Card/Card";

import "./MasonryLayout.css";

function MasonryLayout({ reviews }) {
  const [showReviews] = useState(3);

  return (
    <div className="masonry-layout">
      {reviews.slice(0, showReviews).map((review, index) => (
        <div className="masonry-item" key={index}>
          <Card review={review} />
        </div>
      ))}
      {showReviews < reviews.length && (
        <a
          href="https://g.page/Concordville-Cleaners"
          target="_blank"
          rel="noreferrer"
          className="btn"
        >
          More Google Reviews
        </a>
      )}
    </div>
  );
}

export default MasonryLayout;
