import Navbar from "./components/Navbar/Navbar";
import Hero from "./pages/Hero/Hero";
import Services from "./pages/Services/Services";
import About from "./pages/About/About";
import Delivery from "./pages/Delivery/Delivery";
import Alterations from "./pages/Alterations/Alterations";
import Reviews from "./pages/Reviews/Reviews";
import Footer from "./pages/Footer/Footer";
import "./App.css";

function App() {
  return (
    <main>
      <Navbar />
      <Hero />
      <Services />
      <Alterations />
      <Delivery />
      <About />
      <Reviews />
      <Footer />
    </main>
  );
}

export default App;
