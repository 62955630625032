import React from "react";
import "./Services.css";
import images from "../../constants/images";

const Services = () => {
  return (
    <section id="services_container">
      <div className="container">
        <div className="services-order-icons">
          <ul className="service-icons">
            <li>
              <div className="img-container">
                <img src={images.dryCleaning} alt="" className="svg-styling" />
              </div>
              Dry Cleaning
            </li>
            <li>
              <div className="img-container">
                <img src={images.weddingDress} alt="" />
              </div>
              Wedding Gown Preservations
            </li>
            <li>
              <div className="img-container">
                <img src={images.blanket} alt="" />
              </div>
              Linens & Comforters
            </li>
            <li>
              <div className="img-container">
                <img src={images.curtains} alt="" />
              </div>
              Households
            </li>
            <li>
              <div className="img-container">
                <img src={images.houseCleaning} alt="" />
              </div>
              House Cleaning
            </li>
            <li>
              <div className="img-container">
                <img src={images.officeCleaning} alt="" />
              </div>
              Office Cleaning
            </li>
          </ul>
          <h2 className="flex-copy-text services-copy">
            We do our best to increase the services available. If you have any
            questions on items that needs to be cleaned but are unsure, please
            don't hesitate to give us a call. Our friendly and knowledgeable
            team is always here to assist you!
          </h2>
        </div>
        <div className="services-copy copy-container services-order-copy">
          <h2 className="title-text">
            <em>Services we offer</em>
          </h2>
          <h3 className="flex-copy-heading">
            Exceptional Dry Cleaning Services
          </h3>
          <h4 className="flex-copy-text">
            At Concordville Cleaners, we take pride in our comprehensive range
            of dry cleaning services that cater to all your needs.
          </h4>
          <br />
          <h5 className="flex-copy-text">
            Our services have grown over the years to accomodate a wider range
            of garments, from wedding gown restoration and preservation to
            Gore-tex treatment and everything in between. We understand that
            your garments are valuable and have sentimental or monetary value,
            and we take extra care when handling them to ensure that they are
            cleaned to the highest standard. The highest quality workmanship is
            at the core of our business.
          </h5>
        </div>
      </div>
    </section>
  );
};

export default Services;
