import ccLogoSm from "../assets/cc-logo-50x100.png";
import ccLogoLg from "../assets/cc-logo-100x200.png";
import pins from "../assets/clothes_pins.webp";
import pins2 from "../assets/clothes_pins-smallest.webp";
import door from "../assets/door.webp";
import door2 from "../assets/door-smallest.webp";
import heroImg1 from "../assets/hero-jacket-1.webp";
import mirrors from "../assets/mirrors.webp";
import mirrors2 from "../assets/mirrors2.webp";

// SVG icons
import dryCleaning from "../assets/icons/dry-cleaning.svg";
import weddingDress from "../assets/icons/wedding-dress.svg";
import blanket from "../assets/icons/blanket.svg";
import curtains from "../assets/icons/curtains.svg";
import houseCleaning from "../assets/icons/house.svg";
import officeCleaning from "../assets/icons/office-cleaning.svg";

const images = {
  ccLogoSm,
  ccLogoLg,
  heroImg1,
  pins,
  pins2,
  door,
  door2,
  mirrors,
  mirrors2,
  dryCleaning,
  weddingDress,
  blanket,
  curtains,
  houseCleaning,
  officeCleaning,
};

export default images;
