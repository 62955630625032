import React from "react";
import "./Reviews.css";
import images from "../../constants/images";
import { AiFillStar } from "react-icons/ai";
import MasonryLayout from "../../components/MasonryLayout/MasonryLayout";

import { onlineReviews } from "../../constants/reviews";

const Reviews = () => {
  return (
    <section id="reviews_container">
      <div className="reviews-container">
        <img src={images.pins2} alt="clothes pins hanging on a wire" />
      </div>
      <div className="heading-container flex-container">
        <div className="reviews-copy">
          <h2 className="title-text">
            <em>Read What our Customers are Saying</em>
          </h2>
          <div className="review-stars">
            <AiFillStar role="img" aria-label="Golden Star" />
            <AiFillStar role="img" aria-label="Golden Star" />
            <AiFillStar role="img" aria-label="Golden Star" />
            <AiFillStar role="img" aria-label="Golden Star" />
            <AiFillStar role="img" aria-label="Golden Star" />
          </div>
          <h3 className="flex-copy-heading">Over 100+ 5 Star Reviews</h3>
        </div>
      </div>

      <div className="card-container">
        <MasonryLayout reviews={onlineReviews} />
      </div>
    </section>
  );
};

export default Reviews;
