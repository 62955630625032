import React from "react";
import { FiPhone } from "react-icons/fi";
import { FaYelp, FaGoogle } from "react-icons/fa";

import "./Footer.css";

const Footer = () => (
  <div className="app__footer section__padding" id="login">
    <div className="app__footer-links footer-container flex-container">
      <div className="app__footer-links_contact">
        <h2 className="app__footer-headtext">Contact Us</h2>
        <h3>
          301 Byers Dr Suite #1 <br /> Glen Mills, PA 19342{" "}
        </h3>
        <a id="phone-number" href="tel:+16103583076">
          (610) 358-3076
        </a>

        <h3>
          <strong>Email:</strong>{" "}
          <a id="email" href="mailto:concordvillecleaners@gmail.com">
            {" "}
            concordvillecleaners@gmail.com
          </a>
        </h3>
      </div>

      <div className="app__footer-links_logo">
        <div className="app__footer-links_icons">
          <a
            href="https://g.page/Concordville-Cleaners"
            target="_blank"
            rel="noreferrer"
          >
            <FaGoogle aria-label="Google Review Link" role="img" />
          </a>
          <a
            href="https://www.yelp.com/biz/concordville-cleaners-glen-mills"
            target="_blank"
            rel="noreferrer"
          >
            <FaYelp aria-label="Yelp Review Link" role="img" />
          </a>
          <a href="tel:+16103583076">
            <FiPhone aria-label="Telephone Number to call store" role="img" />
          </a>
        </div>
      </div>

      <div className="app__footer-links_work">
        <h2 className="app__footer-headtext">Hours</h2>
        <h3>Monday - Friday:</h3>
        <h3>9:00 am - 6:00 pm</h3>
        <h3>Saturday: </h3>
        <h3>9:00 am - 3:00 pm</h3>
        <h3>Sunday: </h3>
        <h3>Closed</h3>
        <br />
        <h4 className="h4__sans-serif">Holiday hours may differ</h4>
      </div>
    </div>

    <div className="footer__copyright">
      <h4>&#169; 2023 Concordville Cleaners. All Rights reserved.</h4>
    </div>
  </div>
);

export default Footer;
