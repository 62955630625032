import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdOutlineClose } from "react-icons/md";
import { FaGoogle, FaYelp } from "react-icons/fa";
import { FiPhone } from "react-icons/fi";

import images from "../../constants/images";

import "./Navbar.css";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [colorChange, setColorChange] = useState(false);

  const changeNavbarColor = () => {
    if (window.scrollY >= 500) {
      setColorChange(true);
    } else {
      setColorChange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  return (
    <nav className={colorChange ? "navbar colorChange" : "navbar"}>
      <a href="#hero_container" className="navbar__logo">
        <img src={images.ccLogoLg} alt="Concordville Cleaners" />
      </a>
      <ul className="navbar__list">
        <li className="navbar__links">
          <a href="#services_container">Services</a>
        </li>
        <li className="navbar__links">
          <a href="#alterations_container">Alterations</a>
        </li>
        <li className="navbar__links">
          <a href="#delivery_container">Delivery</a>
        </li>
        <li className="navbar__links">
          <a href="#about_container">About</a>
        </li>
        <li className="navbar__links">
          <a href="#reviews_container">Reviews</a>
        </li>
      </ul>
      <ul className="hero__social-list">
        <li className="hero__social-links">
          <a
            href="https://g.page/r/Cf6mBdArm1v1EBE/"
            target="_blank"
            rel="noreferrer"
          >
            <FaGoogle aria-label="Google Review Link" role="img" />
          </a>
        </li>
        <li className="hero__social-links">
          <a
            href="https://www.yelp.com/biz/concordville-cleaners-glen-mills"
            target="_blank"
            rel="noreferrer"
          >
            <FaYelp aria-label="Yelp Review Link" role="img" />
          </a>
        </li>
        <li className="hero__social-links">
          <a href="tel:6103583076" target="_blank" rel="noreferrer">
            <FiPhone aria-label="Telephone Number to call store" role="img" />
          </a>
        </li>
      </ul>

      <div className="navbar__mobile">
        <GiHamburgerMenu
          color="#d6be93"
          fontSize={27}
          onClick={() => {
            setToggleMenu(true);
          }}
        />

        {toggleMenu && (
          <div className="navbar__mobile-overlay flex__center slide-bottom">
            <MdOutlineClose
              fontSize={27}
              className="overlay__close"
              onClick={() => {
                setToggleMenu(false);
              }}
            />

            <ul
              className="navbar__mobile-links"
              onClick={() => {
                setToggleMenu(false);
              }}
            >
              <li className="navbar__links">
                <a href="#hero_container">Home</a>
              </li>
              <li className="navbar__links">
                <a href="#services_container">Services</a>
              </li>
              <li className="navbar__links">
                <a href="#alterations_container">Alterations</a>
              </li>
              <li className="navbar__links">
                <a href="#delivery_container">Delivery</a>
              </li>

              <li className="navbar__links">
                <a href="#about_container">About</a>
              </li>
              <li className="navbar__links">
                <a href="#reviews_container">Reviews</a>
              </li>
            </ul>
            <ul className="hero__social-list-mobile">
              <li className="hero__social-links-mobile">
                <a
                  href="https://g.page/r/Cf6mBdArm1v1EBE/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaGoogle />
                </a>
              </li>
              <li className="hero__social-links-mobile">
                <a
                  href="https://www.yelp.com/biz/concordville-cleaners-glen-mills"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaYelp />
                </a>
              </li>
              <li className="hero__social-links-mobile">
                <a href="tel:+16103583076">
                  <FiPhone />
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
