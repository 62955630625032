export const onlineReviews = [
  {
    id: 1,
    name: "Liz E.",
    review:
      "The amazing people here have expertly altered my suits, jeans and even a leather skirt. I can't recommend this place enough for great work, at fair prices.",
    date: "9/27/2022",
    source: "Google",
    category: "Alterations",
  },
  {
    id: 2,
    name: "Marissa Bonanno",
    review:
      "The staff is so nice and friendly here! They shortened a satin bridal party dress for me in two weeks and they did great work.",
    date: "10/6/2022",
    source: "Google",
    category: "Alterations",
  },
  {
    id: 3,
    name: "Ruth Miner",
    review:
      "Quality service and extremely helpful! Great job on alterations! Very professional and friendly! I highly recommend them",
    date: "5/27/2018",
    source: "Google",
    category: "Dry Cleaning",
  },

  {
    id: 4,
    name: "Lori And Jack L.",
    review:
      "We just moved to Glen Mills PA and I had heard good things about Concordville cleaners and ask them to do a deep cleaning on the new home that we purchased. It is a large home with quite a bit of detail cleaning work needed as previous owners did not do an exit cleaning. I was really impressed with the team including working with me in terms of setting up of the appointment and expectations, and the cleaning crew was excellent. Contact and communication went well and the team worked hard while they were here and also worked with me as I pointed out problem areas.  Highly recommend!",
    date: "8/17/2022",
    source: "Yelp",
    category: "Home",
  },
  {
    id: 5,
    name: "Patrice Y.",
    review:
      "I don't recommend waiting until the last minute...HOWEVER....I had purchased a black suit last year and never had the opportunity to wear it.  As a result of a sudden death in our family, I went to try on my suit to make sure it was clean and realized I had never gotten it altered.  The sleeves of the jacket and hem on the pants needed to be done.  I went to Concordville Cleaners and spoke to the sweet young lady (I don't know her name) and begged that they do it and have it back the next day.  She called their other store, explained the situation and I immediately was on my way to Kennett Square to have the work completed.  Both stores, as usual, went above and beyond for me.  They even returned the suit to my Glen Mills location to make it more convenient for me.  I was so touched by the level of service that I received and have always recommended Concordville Cleaners to all I know as well as on the Next Door app.  Thank you from the bottom of my heart for helping me through such a difficult situation.  Your quality of work never disappoints.",
    date: "4/9/2022",
    source: "Yelp",
    category: "Alterations",
  },
  {
    id: 6,
    name: "Kayla Manna",
    review:
      "I don’t normally write reviews but felt I needed to share my recent visit. I contacted them through their Facebook page on Saturday at 11:30 at night after realizing I never picked up my sons suits from a recent wedding we attended. I needed their suits for my niece and nephews baptism the next morning and they are closed on Sunday’s. I offered to pay an additional fee if someone could open briefly to allow me to pick up their suits. Not only did he respond that night he agreed to meet me and rearranged his whole morning to help me while he had a flight to catch at noon! I paid my cost for their suits to be cleaned and attempted to tip him for going out of his way to save my day! He refused to accept the tip! I am so thankful for everything he did for me that day! The suits were perfect and ready to go! I have never had a loyalty to any one dry cleaner. I just always went to anyone convenient on my way.  He will always have our business!",
    date: "10/27/2021",
    source: "Google",
    category: "Customer Service",
  },
  {
    id: 7,
    name: "Jenn A",
    review:
      "Concordville Cleaners is the only place I'll go for dry cleaning and alterations. Even when I moved away for a short time, I still came here anytime I needed it. They have great pricing and attention to detail. From dresses to blankets, I've never had a bad experience and recommend them to everyone!",
    date: "7/27/2022",
    source: "Google",
    category: "Dry Cleaning",
  },
  {
    id: 8,
    name: "Thomas Salerno",
    review:
      "Needed a quick turnaround on a bridal party dress. They were quick and the results were very good. Highly recommended!!",
    date: "10/6/2022",
    source: "Google",
    category: "Alterations",
  },
  {
    id: 9,
    name: "Morgan Brown",
    review:
      "Im in town on business and I stopped in the drop off dry cleaning for the first time. The woman who assisted me was super nice and very pleasant. The prices were comparable to what I pay at home in Colorado and the time alotted for the cleanings. I think it was an all around great experience and I will continue using them throughout my time here in PA.",
    date: "9/27/2022",
    source: "Google",
    category: "Dry Cleaning",
  },
  {
    id: 10,
    name: "Nora Fitzgerald",
    review:
      "I needed a last minute hem on a dress for my sister's wedding. Phil helped me work it out as a first time client which was so appreciated since this was a pretty important event! Linda was so sweet and they both worked so efficiently to make it happen in time for me. They will now be my go to for tailoring AND dry cleaning needs--both the Glen Mills and Kennett locations!",
    date: "10/27/2021",
    source: "Google",
    category: "Customer Service",
  },
  {
    id: 11,
    name: "Taylor Cannon",
    review:
      "The only place I get my alterations done  now . They do a excellent job. Owner is so nice too. Never had a issue . :)",
    date: "10/27/2021",
    source: "Google",
    category: "Customer Service",
  },
];
